/* GLOBAL CSS STYLES */

* {
  box-sizing: border-box;
}

:root {
  font-family: "Muli", sans-serif;
}

body {
  margin: 0;
  color: #fff;
  background-color: #1f1f1f;
}

/* Mozilla offscreen CSS */
.offscreen {
  border: 0;
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media (max-width: 400px), (max-height: 400px) {
  :root {
    font-size: 0.8rem;
  }
}
